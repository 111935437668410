<template>
  <div class="privacy">
    <h1>個人資料蒐集政策與隱私權保護</h1>
    <p class="mt-2">
      本會員網站由寒舍餐旅管理顧問股份有限公司經營管理，為確保會員之個人資料、隱私及消費者權益之保護，於交易過程中將使用會員之個人資料，謹依個人資料保護法告知以下事項,且本政策將因業務、法令或環境等因素之更迭時審議後予以適當修訂，並將依法更新公告於本公司網站上，除法定通知義務外，不另行通知．
    </p>
    <p class="mt-1">
      1．蒐集目的及方式:為確認您使用APP所進行的相關作業、帳務管理、物品寄送、客戶管理以及方便您日後取得相關會員服務與優惠活動資訊等電子報或郵寄DM，且蒐集方式將透過加入會員方式進行個人資料之蒐集。
    </p>
    <p class="mt-1">2．蒐集之個人資料類別:</p>
    <blockquote>
      <p>本公司於網站內蒐集的個人資料包括：</p>
      <p>（一）C001辨識個人者：如會員之姓名、地址、電話、電子郵件等資訊。</p>
      <p>（二）C003政府資料中之辨識者：如身分證字號或護照號碼(外國人)。</p>
      <p>（三）C011個人描述：如性別、出生年月日。</p>
      <p>（四）C013習慣：如喝酒。</p>
      <p>（五）C036生活格調: 個人或家庭之消費模式。</p>
    </blockquote>
    <p class="mt-1">3．利用期間、地區、對象及方式:</p>
    <blockquote>
      <p>（一）期間：本集團營運期間。</p>
      <p>
        （二）地區：本公司營運地區、本公司業務委外機構所在地、與本公司具有業務往來之機構營業處所所在地。
      </p>
      <p>
        （三）利用對象及方式：會員個人資料將由本公司暨本公司所屬機構及關係企業、本公司海外分支機構、聯合信用卡中心、信用卡收單機構、業務委外機構(包括相關物流、郵寄廠商)、本公司之共同行銷廠商及合作推廣業務之單位、依法有監管權限之政府機關，於前述目的範圍內，以自動化機器或其他非自動化之紙本、口頭之利用方式依法令規定進行利用。
      </p>
    </blockquote>
    <p class="mt-1">4．會員個人資料之權利:</p>
    <blockquote>
      <p>會員依法得行使個資法第3條之權利：</p>
      <p>（一）查詢或請求閱覽。</p>
      <p>（二）請求製給複製本。</p>
      <p>（三）補充或更正。</p>
      <p>（四）請求停止蒐集、處理或利用。</p>
      <p>（五）請求刪除。</p>
      <blockquote>
        <p>
          得以電子郵件（ITF@mhh-group.com）向本公司提出進行申請，本公司並保留酌收必要成本費用之權利。
        </p>
      </blockquote>
    </blockquote>
    <p class="mt-1">
      5．本公司為提供會員優質的服務與提供行銷活動資訊而蒐集、處理或利用會員個人資料，會員本人得選擇是否提供個人資料。若選擇不提供或提供不完全時，本公司可能無法提供會員完整的服務。
    </p>
    <br />
    <br />

    <h1>與第三者共用個人資料之政策</h1>
    <p class="mt-2">
      1. 在未取得您的同意之前，寒舍集團於APP及本網站上所蒐集的個人資料絕不會提供給其他團體或個人，或用於上述目的以外之其他用途。
    </p>
    <p>
      2. 若為了提供其他服務或優惠權益，需要與提供該服務或優惠第三者共用使用者資料時（與其他業者共同推出活動或特惠），會在活動時提供充分說明，您可以自由選擇是否接受該項特定服務或優惠。
    </p>
    <br />
    <br />

    <h1>Cookies的運用與政策</h1>
    <p class="mt-2">
      所謂的Cookies是伺服器端為了區別使用者的不同喜好，經由瀏覽器寫入使用者硬碟的一些簡短資訊。您可以選擇修改您的瀏覽器對Cookies的接受程度，如果您使用IE，這項功能在「Internet選項」的「安全性」中，如果您使用Netscape，則此功能在「功能設定」的「進階」中，在這個地方，有三種層級，包括接受所有cookies、設定cookies時得到通知、拒絕所有cookies等。如果您選擇拒絕所有的cookies，您就可能無法使用部份個人化服務，或是參與網站上部份的活動。
    </p>
    <p>
      而寒舍集團為了提升使用者輸入資料的方便性及正確性，以及提供更好的服務，網站上各資料欄位會採用cookies方式來儲存您輸入過的資料。所以本網站會在本政策原則之下，在您瀏覽器中寫入並讀取Cookies。
    </p>
    <br />
    <br />

    <h1>有關隱私權保障條款之修正權利</h1>
    <p class="mt-2">
      APP及本網站的隱私權保護條款，將因應科技發展趨勢、相關法規之修訂或其他環境變遷等因素做適當之修改，以落實保障使用者隱私權之立意。修改過的條款將隨時調整，立即刊登於本網站中。
    </p>
  </div>
</template>

<style scoped>
h1 {
  font-size: 20px;
  font-family: PingFang TC, sans-serif;
}

.privacy {
  padding: 48px 24px;
}

p {
  text-align: justify;
  text-justify: inter-word;
}

@media (max-width: 340px) {
  .privacy {
    padding: 24px 24px;
  }
  h1 {
    font-size: 16px;
  }
  p {
    font-size: 13px;
  }
}
</style>
